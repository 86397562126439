<template>
  <div class="participants-filter">
    <select-component
        :data="channelsList"
        label="Channel"
        prop-for-display="name"
        v-bind:selectedValue.sync="selectedChannel"
    />
    <select-component
        label="Division"
        :data="divisionList"
        :disabled="!divisionList.length"
        v-bind:selectedValue.sync="selectedDivision"
    />
    <select-component
        :data="groupsList"
        label="Group"
        :disabled="!groupsList.length"
        v-bind:selectedValue.sync="selectedGroup"
    />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import SelectComponent from "@/components/base-components/SelectComponent";

const BLANK_FIELD = {
  name: '',
  id: null
};
export default {
  name: "ParticipantsFilter",
  components: {SelectComponent},
  props: {
    participantGroupId: {
      type: Number,
      default: null,
    }
  },
  data(){
    return {
      selectedParticipantId: null,
      selectedChannel: null,
      selectedDivision: null,
      selectedGroup: null,
      channelsList: [],
    }
  },
  methods: {
    ...mapActions(['GET_PARTICIPANTS_GROUP']),
    getParticipants() {
      this.GET_PARTICIPANTS_GROUP()
          .then(({data}) =>{
            this.channelsList = [BLANK_FIELD, ...data] || [];
          })
          .catch(err => {
            this.channelsList = [];
          })
    },
    emitParticipant(id){
      this.$emit("update:participantGroupId", id)
    }
  },
  watch:{
    selectedChannel(newId){
      this.selectedDivision = null;
      this.selectedParticipantId = newId;
    },
    selectedDivision(newId){
      this.selectedGroup = null;
      this.selectedParticipantId =  newId || this.selectedChannel;
    },
    selectedGroup(newId){
      this.selectedParticipantId = newId || this.selectedDivision ||  this.selectedChannel;
    },
    selectedParticipantId(newId){
      this.emitParticipant(newId)
    }
  },
  computed: {
    divisionList() {
      const divisions = this.selectedChannel && this.channelsList?.find(({id}) => Number(id) === Number(this.selectedChannel))?.children
      return divisions? [BLANK_FIELD, ...divisions] : [];
    },
    groupsList(){
      const groups = this.selectedDivision && this.divisionList?.find(({id}) => Number(id) === Number(this.selectedDivision))?.children;
      return groups? [BLANK_FIELD, ...groups] : [];
    }
  },
  mounted() {
    this.getParticipants()
  }
}
</script>

<style scoped>
.participants-filter{
  display: grid;
  grid-template-columns: repeat( auto-fill,  minmax(20rem, 1fr));
  gap: 2rem;
  align-items: center;
  padding-bottom: 1rem;
}

</style>